import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { OrderHistoryListResponse } from "../shared/models/history.model";
import { RequestParams } from "../shared/models/list.model";
import {
  NewOrder,
  Order,
  OrderFile,
  OrdersListRespone,
  TOrder,
} from "../shared/models/orders.model";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class OrdersService {
  onEditEvent: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private authService: AuthService) {}

  getOrdersList(params: RequestParams): Observable<OrdersListRespone> {
    return this.http
      .get(`${environment.apiUrl}/${this.authService.getUserType()}/orders`, {
        params: params,
      })
      .pipe(map((res: OrdersListRespone) => res));
  }

  getOrdersListFilteredByTaskDate(
    params: RequestParams
  ): Observable<OrdersListRespone> {
    return this.http
      .get(
        `${environment.apiUrl}/${this.authService.getUserType()}/tasks/orders`,
        { params: params }
      )
      .pipe(map((res: OrdersListRespone) => res));
  }

  closeOrders(orderIds: string[]) {
    return this.http.post<any>(
      `${
        environment.apiUrl
      }/${this.authService.getUserType()}/orders/completed`,
      { orderIds: orderIds }
    );
  }

  getOrder(orderTypeUuid: Order["uuid"]): Observable<any> {
    return this.http
      .get(
        `${
          environment.apiUrl
        }/${this.authService.getUserType()}/orders/${orderTypeUuid}`
      )
      .pipe(
        map((res: any) => {
          res.order &&
            res.order.files &&
            res.order.files.forEach((file) => {
              file.url = `${
                environment.apiUrl
              }/${this.authService.getUserType()}/orders/${
                res.order.uuid
              }/files/${file.fileId}`;
            });
          return res.order;
        })
      );
  }

  getOrderHistory(orderTypeUuid: Order["uuid"], params: RequestParams) {
    return this.http.get<OrderHistoryListResponse>(
      `${
        environment.apiUrl
      }/${this.authService.getUserType()}/orders/${orderTypeUuid}/history`,
      { params: params }
    );
  }

  getOrderHistoryNew(orderTypeUuid: Order["uuid"], params: RequestParams) {
    return this.http.get<OrderHistoryListResponse>(
      `${
        environment.apiUrl
      }/${this.authService.getUserType()}/orders/${orderTypeUuid}/history/v2`,
      { params: params }
    );
  }

  createOrder(order: NewOrder): Observable<TOrder> {
    return this.http
      .post<any>(
        `${environment.apiUrl}/${this.authService.getUserType()}/orders`,
        order
      )
      .pipe(map((res: any) => res.order));
  }

  updateOrder(orderId: Order["uuid"], order: Order): Observable<Order> {
    return this.http.put<any>(
      `${
        environment.apiUrl
      }/${this.authService.getUserType()}/orders/${orderId}`,
      order
    );
  }

  updateOrderDate(
    orderId: Order["uuid"],
    suggestedDate: Order
  ): Observable<Order> {
    return this.http.put<any>(
      `${
        environment.apiUrl
      }/${this.authService.getUserType()}/orders/${orderId}/set-date`,
      { suggestedDate }
    );
  }

  changeOrderStatus(
    orderId: Order["uuid"],
    status: string,
    data?: { closedComment: string }
  ): Observable<Order> {
    return this.http
      .post<any>(
        `${
          environment.apiUrl
        }/${this.authService.getUserType()}/orders/${orderId}/${status}`,
        data ? data : {}
      )
      .pipe(map((res: any) => res.order));
  }

  deleteOrder(orderId: Order["uuid"]) {
    return this.http.delete<any>(
      `${
        environment.apiUrl
      }/${this.authService.getUserType()}/orders/${orderId}`
    );
  }

  sendOrderLink(email: string): Observable<Order> {
    return this.http
      .post<any>(
        `${environment.apiUrl}/${this.authService.getUserType()}/orders/public`,
        { email: email }
      )
      .pipe(map((res: any) => res.order));
  }

  resendNotification(orderId: Order["uuid"]): Observable<Order> {
    return this.http
      .post<any>(
        `${
          environment.apiUrl
        }/${this.authService.getUserType()}/orders/${orderId}/send-notified-notification`,
        {}
      )
      .pipe(map((res: any) => res.order));
  }

  addFile(orderUuid: Order["uuid"], file: OrderFile): Observable<OrderFile> {
    return this.http
      .post<any>(
        `${
          environment.apiUrl
        }/${this.authService.getUserType()}/orders/${orderUuid}/files`,
        file
      )
      .pipe(map((res: any) => res.file));
  }

  deleteFile(
    orderUuid: Order["uuid"],
    fileId: OrderFile["fileId"]
  ): Observable<Order> {
    return this.http
      .delete<any>(
        `${
          environment.apiUrl
        }/${this.authService.getUserType()}/orders/${orderUuid}/files/${fileId}`
      )
      .pipe(map((res: any) => res.order));
  }
}
