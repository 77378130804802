<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <div class="title-wrapper">
        <span class="title dialog-title mr-8">{{getTitle()}} - {{'ORDERS.TYPES.IMPORT' | translate}}</span>
        <span class="title dialog-title" *ngIf="mode !== 'add' && mode !== 'clone'">
          [{{'ORDERS.STATUSES.' + order?.status.toUpperCase() | translate}}]
        </span>
      </div>
      <button mat-icon-button (click)="closeDialog()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
    <div *ngIf="(mode !== 'add' && mode !== 'clone') && order" class="full-width-wrapper-vertical mb-24">
      <h3 class="mt-0"> {{'ORDERS.ORDER_NUMBER' | translate}} </h3>
      <div>{{order.orderNumber || ('ORDERS.NO_NUMBER' | translate)}}</div>
      <hr>
    </div>
    <form class="form" [formGroup]="orderImportForm">
      <div class="full-width-wrapper-horizontal full-name-select">
        <mat-form-field appearance="outline" fxFlex="auto">
          <mat-label>{{'ORDERS.CUSTOMER_COMPANY' | translate}}</mat-label>
          <mat-select formControlName="customerCompany" [compareWith]="compareObjectsByUuid"
                      (valueChange)="setCustomerCompany($event)" #companySelect
                      required [attr.data-cy]="'customerCompany'">
            <mat-option id="searchCompany">
              <ngx-mat-select-search [formControl]="searchCustomerCompany"
                                     [placeholderLabel]="'CUSTOMER_COMPANIES.SEARCH_PLACEHOLDER' | translate"
                                     [noEntriesFoundLabel]="'SHARED.NO_RESULTS' | translate" [attr.data-cy]="'searchCompany'">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option (click)="addCustomerCompany()" [attr.data-cy]="'addCompany'">
              <span class="add-item">
                <mat-icon class="add-item-icon" matSuffix (click)="addCustomerCompany()"
                          [matTooltip]="'CUSTOMER_COMPANIES.TITLE_ADD' | translate"
                          matTooltipPosition="above">
                  add
                </mat-icon>
                {{ 'CUSTOMER_COMPANIES.TITLE_ADD' | translate }}</span>
            </mat-option>
            <mat-option class="full-name-option"
                        *ngFor="let customerCompany of customerCompanyOptions"
                        [value]="customerCompany"
                        [attr.data-cy]="customerCompany.name.split(' ').join('-')">
                  <span matTooltipPosition="above" matTooltipClass="multiline-tooltip"
                        [matTooltip]="getTooltipCompany(customerCompany)">
              {{ customerCompany.name }}
                  </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="input-wrapper-order-import">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'ORDERS.ACCEPTED_BY_SALES' | translate}}</mat-label>
          <mat-select formControlName="salesAccepted" required [attr.data-cy]="'acceptedBySales'">
            <mat-option [value]="true" [attr.data-cy]="'YES'">{{'SHARED.YES' | translate}}</mat-option>
            <mat-option [value]="false" [attr.data-cy]="'NO'">{{'SHARED.NO' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="input-wrapper-order-import">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'ORDERS.COMPANY_BRANCH' | translate}}</mat-label>
          <mat-select formControlName="companyBranchId" required [attr.data-cy]="'companyBranch'">
            <mat-option *ngFor="let branch of companyBranches" [value]="branch.uuid" [attr.data-cy]="branch.name.toUpperCase()">
              {{ branch.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="input-wrapper-order-import">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'ORDERS.TRANSPORT' | translate}}</mat-label>
          <mat-select formControlName="transport"
                      [compareWith]="compareObjectsByUuid"
                      required [attr.data-cy]="'setTransport'">
            <mat-option>
              <ngx-mat-select-search [formControl]="searchTransport"
                                     [placeholderLabel]="'TRANSPORT.SEARCH_PLACEHOLDER' | translate"
                                     [noEntriesFoundLabel]="'SHARED.NO_RESULTS' | translate">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let transport of transportOptions" [value]="transport" [attr.data-cy]="transport.name">
              {{ transport.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="input-wrapper-order-import">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'ORDERS.SUGGESTED_DATE_IMPORT' | translate}}</mat-label>
          <input matInput [matDatepicker]="suggestedDate" formControlName="suggestedDate" [min]="todayDate" readonly>
          <mat-datepicker-toggle *ngIf="mode !== 'view'" matSuffix [for]="suggestedDate" [attr.data-cy]="'suggestedDate'"></mat-datepicker-toggle>
          <mat-datepicker #suggestedDate></mat-datepicker>
        </mat-form-field>
        <button mat-icon-button class="clear-date" *ngIf="mode !== 'view'" (click)="clearStartDate($event)">
          <mat-icon>clear</mat-icon>
        </button>
      </div>

      <div class="input-wrapper-order-import">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'ORDERS.REG_NUMBER_FRONT' | translate}}</mat-label>
          <input formControlName="registrationNumFront" matInput [attr.data-cy]="'numberFront'">
        </mat-form-field>
      </div>

      <div class="input-wrapper-order-import">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'ORDERS.REG_NUMBER_BACK' | translate}}</mat-label>
          <input formControlName="registrationNumBack" matInput [attr.data-cy]="'numberBack'">
        </mat-form-field>
      </div>

      <div class="input-wrapper-order-import">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'ORDERS.REFERENCE_NUMBER' | translate}}</mat-label>
          <input formControlName="referenceNumber" matInput [attr.data-cy]="'referenceNUmber'">
        </mat-form-field>
      </div>

      <div class="input-wrapper-order-import">
        <app-email-chips-input [label]="'ORDERS.KPO_CONTACT'" [mode]="mode" [emailControl]="orderImportForm.controls['kpoEmails']">
        </app-email-chips-input>
      </div>

      <div class="full-width-wrapper-vertical">
        <h3> {{'ORDERS.BDO' | translate}}</h3>

        <div class="full-width-wrapper-horizontal">
          <div class="input-wrapper-order-import-reverse bdo-wrapper-input">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{'ORDERS.BDO_NUMBER' | translate}}</mat-label>
              <input formControlName="bdo"
                     type="text"
                     matInput
                     [attr.data-cy]="'bdoNumber'"
                     [required]="!orderImportForm.get('bdoFree').value && !orderImportForm.get('bdoPeopleCollection').value">
            </mat-form-field>
          </div>
          <div class="input-wrapper-order-import-reverse bdo-checkbox-wrapper bdo-wrapper">
            <div class="or-text">{{'SHARED.OR' | translate}}</div>
            <mat-checkbox class="bdo-required" formControlName="bdoFree" id="bdoCheckbox">
              {{'ORDERS.NO_BDO_CHECKBOX' | translate}}
            </mat-checkbox>
          </div>
          <div class="input-wrapper-order-receiving-reverse bdo-checkbox-wrapper bdo-wrapper">
            <div class="or-text">{{'SHARED.OR' | translate}}</div>
            <mat-checkbox class="bdo-required" formControlName="bdoPeopleCollection" [attr.data-cy]="'bdoPeopleCollection'">
              {{'ORDERS.BDO_PEOPLE_COLLECTION' | translate}}
            </mat-checkbox>
          </div>
        </div>
      </div>


      <div class="full-width-wrapper-vertical">
        <app-items-list-picker-form
          [mode]="mode"
          [required]="true"
          [formControl]="orderImportForm.controls['orderData']"
        >
        </app-items-list-picker-form>
      </div>

      <div *ngIf="user.userType === userTypes.Logistician" class="full-width-wrapper-vertical">
        <app-tags-autocomplete
          [mode]="mode"
          [tagsControl]="orderImportForm.controls['tags']"
        >
        </app-tags-autocomplete>
      </div>

      <div class="full-width-wrapper-vertical">
        <h3> {{'ORDERS.INTERNAL_COMMENT' | translate}} </h3>
        <mat-form-field appearance="outline" fxFlex="auto">
          <textarea rows="4" formControlName="comment" matInput></textarea>
        </mat-form-field>
      </div>


         <div class="full-width-wrapper-horizontal reinvoice">
        <div class="input-wrapper-order-import">
            <mat-checkbox formControlName="reinvoice"
                          (change)="reinvoiceChange($event.checked)"
                          [attr.data-cy]="'reinvoice'">
                {{'ORDERS.REINVOICE_CHECKBOX' | translate}}
            </mat-checkbox>
        </div>

        <div class="input-wrapper-order-import">
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'ORDERS.REINVOICE_VALUE' | translate}}</mat-label>
                <input formControlName="price" matInput
                       [attr.data-cy]="'price'">
            </mat-form-field>
        </div>

        <div class="input-wrapper-order-import">
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'ORDERS.REINVOICE_NUMBER' | translate}}</mat-label>
                <input formControlName="reinvoiceNumber" matInput
                       [attr.data-cy]="'reinvoiceNumber'">
            </mat-form-field>
        </div>
    </div>
    <div class="full-width-wrapper-vertical">
        <h3 class="m-0"> {{'ORDERS.INTERNAL_COMMENT_FV_ISSUER' | translate}}</h3>
        <mat-form-field appearance="outline" fxFlex="auto">
                <textarea formControlName="invoiceComment" matInput
                          [attr.data-cy]="'invoiceComment'"></textarea>
        </mat-form-field>
    </div>

      <div class="full-width-wrapper-vertical">
        <h3> {{'ORDERS.STATUS' | translate}} </h3>
        <mat-form-field appearance="outline" fxFlex="auto">
          <mat-label>{{'ORDERS.STATUS' | translate}}</mat-label>
          <mat-select formControlName="status">
            <mat-option value="new">{{'ORDERS.STATUSES.NEW' | translate}}</mat-option>
            <mat-option value="read">{{'ORDERS.STATUSES.READ' | translate}}</mat-option>
            <mat-option value="planned">{{'ORDERS.STATUSES.PLANNED' | translate}}</mat-option>
            <mat-option value="cancelled">{{'ORDERS.STATUSES.CANCELLED' | translate}}</mat-option>
            <mat-option value="completed">{{'ORDERS.STATUSES.COMPLETED' | translate}}</mat-option>
            <mat-option *ngIf="canCloseOrder" value="closed">{{'ORDERS.STATUSES.CLOSED' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>

    <div *ngIf="mode === 'view' && order">
      <app-order-details-form [orderData]="order"></app-order-details-form>
    </div>
  </div>


  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
    <button *ngIf="mode !== 'add' && mode !== 'clone' && mode !=='edit' && canGenerateTask"
            mat-button
            class="mr-8"
            [attr.data-cy]="'generateTask'"
            (click)="generateTask()">
      {{'ORDERS.GENERATE_TASK' | translate}}
    </button>

    <button *ngIf="mode !== 'add' && mode !== 'clone' && canViewTask"
            mat-button
            class="mr-8"
            (click)="viewTask()">
      {{'ORDERS.VIEW_TASK' | translate}}
    </button>

    <button *ngIf="mode === 'edit' && canCancelOrder"
            mat-button
            class="mr-8"
            (click)="changeOrderStatus(orderStatuses.Cancelled)">
      {{'ORDERS.CANCEL_ORDER' | translate}}
    </button>

    <button *ngIf="mode === 'edit'"
            mat-button
            class="mr-8"
            [attr.data-cy]="'deleteOrder'"
            (click)="deleteOrder()">
      {{'ORDERS.DELETE' | translate}}
    </button>

    <button *ngIf="mode === 'view' && user.userType === userTypes.Logistician"
            mat-button
            class="mr-8"
            [attr.data-cy]="'showHistory'"
            (click)="showHistory()">
      {{'ORDERS.SHOW_HISTORY' | translate}}
    </button>
    <button *ngIf="mode === 'view'"
            mat-button
            class="mr-8"
            [attr.data-cy]="'cloneOrder'"
            (click)="enableCloneMode()">
      {{'ORDERS.CLONE' | translate}}
    </button>
    <button *ngIf="mode === 'view' && canUserEdit"
            mat-button
            class="mr-8"
            [attr.data-cy]="'editOrder'"
            (click)="enableEditMode()">
      {{'ORDERS.EDIT' | translate}}
    </button>


    <button *ngIf="mode === 'edit' || mode === 'add' || mode === 'clone' || !canUserEdit"
            mat-button
            class="save-button"
            (click)="submitOrder()"
            [attr.data-cy]="'saveOrder'"
            [disabled]="isLoading">
      <span *ngIf="!isLoading"> {{'ORDERS.SAVE' | translate}}</span>
      <span *ngIf="isLoading" class="loading-spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"
                              [strokeWidth]="2"
                              [diameter]="20">
        </mat-progress-spinner>
      </span>
    </button>
  </div>
</div>