import {
  Component,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatSnackBar,
} from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { zip } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { FuseTranslationLoaderService } from "../../../../../../@fuse/services/translation-loader.service";
import { AuthService } from "../../../../../services/auth.service";
import { CompaniesService } from "../../../../../services/companies.service";
import { FilteredListsService } from "../../../../../services/filtered-lists.service";
import { OrdersService } from "../../../../../services/orders.service";
import { UsersService } from "../../../../../services/users.service";
import { Mode } from "../../../../../shared/consts/mode";
import { OrderTypes } from "../../../../../shared/consts/orders";
import { Company } from "../../../../../shared/models/companies.model";
import { CompanyBranch } from "../../../../../shared/models/company.model";
import {
  FilteredCompany,
  FilteredTransport,
} from "../../../../../shared/models/filtered-items.model";
import { ItemsOrigins } from "../../../../../shared/models/history.model";
import { RequestParams } from "../../../../../shared/models/list.model";
import {
  OrderImport,
  OrderSatuses,
} from "../../../../../shared/models/orders.model";
import { RoleTypes, UserTypes } from "../../../../../shared/models/users.model";
import { markFormGroupTouched } from "../../../../../shared/utils/markFormGroupAsTouched";
import {
  mapOrderDataApiValues,
  mapOrderDataToUuid,
} from "../../../../../shared/utils/tasks-map";
import { ValidateItemsList } from "../../../../../shared/validators/itemsList.validator";
import { locale as english } from "../../../../../translations/en";
import { locale as polish } from "../../../../../translations/pl";
import { CompanyEditorComponent } from "../../../../companies/components/company-editor/company-editor.component";
import { ConfirmModalComponent } from "../../../../shared/components/confirm-modal/confirm-modal.component";
import { HistoryDialogComponent } from "../../../../shared/components/history-dialog/history-dialog.component";
import { TaskImportFormComponent } from "../../../../tasks/components/task-forms/task-import-form/task-import-form.component";

@Component({
  selector: "app-order-import-form",
  templateUrl: "./order-import-form.component.html",
  styleUrls: ["./order-import-form.component.scss"],
})
export class OrderImportFormComponent implements OnInit {
  @ViewChild("companySelect") companySelect;
  public orderImportForm: FormGroup;
  public orderUuid: OrderImport["uuid"];
  public order: OrderImport;
  public orderStatuses = OrderSatuses;
  public user;
  public userTypes = UserTypes;
  public roleTypes = RoleTypes;
  public canUserEdit: boolean = false;
  public canCancelOrder: boolean = false;
  public canCloseOrder: boolean = false;
  public canGenerateTask: boolean = false;
  public canViewTask: boolean = false;
  public transportOptions: FilteredTransport[] = [];
  public customerCompanyOptions: FilteredCompany[] = [];
  public companyBranches: CompanyBranch[] = [];
  public mode: Mode;
  public isLoading = false;
  public searchCustomerCompany: FormControl;
  public searchTransport: FormControl;
  public todayDate: Date;
  private bdo: string;
  private filterParams: RequestParams = {
    page: 1,
    limit: 20,
    sort: "name",
    order: "asc",
  };
  private filterParamsFullList: RequestParams = {
    page: 1,
    limit: 1000,
    sort: "name",
    order: "asc",
    active: "true",
  };

  constructor(
    public orderEditorDialogRef: MatDialogRef<OrderImportFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private matDialog: MatDialog,
    private authService: AuthService,
    private ordersService: OrdersService,
    private companiesService: CompaniesService,
    private filteredListsService: FilteredListsService,
    private usersService: UsersService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    this.mode = data.mode;
    this.orderUuid = data.orderUuid;
    this.user = this.authService.user.user;

    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }

  ngOnInit() {
    this.createForm();
    this.createSearchFilters();

    if (this.mode === "edit" || this.mode === "view" || this.mode === "clone") {
      this.ordersService
        .getOrder(this.orderUuid)
        .pipe(
          map((res) => {
            this.order = res;

            this.canCancelOrder =
              this.order &&
              this.order.status !== this.orderStatuses.Cancelled &&
              (this.user.userType === this.userTypes.Sales ||
                this.user.userType === this.userTypes.Logistician ||
                this.user.uuid === this.order.createdBy.uuid);

            this.canCloseOrder =
              this.order &&
              (this.order.status === this.orderStatuses.New ||
                this.order.status === this.orderStatuses.Read) &&
              (this.user.userType === this.userTypes.Sales ||
                this.user.userType === this.userTypes.Logistician ||
                this.user.uuid === this.order.createdBy.uuid);

            this.canUserEdit =
              this.order &&
              !this.order.taskNumber &&
              (this.user.userType === this.userTypes.Sales ||
                this.user.userType === this.userTypes.Logistician ||
                this.user.uuid === this.order.createdBy.uuid);

            this.canGenerateTask =
              this.order &&
              this.order.status !== this.orderStatuses.Cancelled &&
              !this.order.taskNumber &&
              this.user.userType === this.userTypes.Logistician;

            this.canViewTask =
              this.order &&
              this.order.taskNumber &&
              this.user.userType === this.userTypes.Logistician;
          }),
          switchMap(() => {
            return zip(
              this.filteredListsService.getTransportList(
                this.filterParamsFullList
              ),
              this.filteredListsService.getCompaniesList(this.filterParams),
              this.usersService.getCompanyBranchList(),
              this.companiesService.getCompany(this.order.customerCompany.uuid)
            );
          })
        )
        .subscribe(([transportRes, companiesRes, branchesRes, company]) => {
          this.transportOptions = transportRes.transports;
          this.customerCompanyOptions = companiesRes.customerCompanies;
          this.companyBranches = branchesRes.branches;
          this.bdo = company.bdo;
          this.createForm(this.order);
          this.checkCustomerCompaniesMissing();
          this.checkTransportMissing();
        });
    } else {
      this.todayDate = new Date();

      this.filteredListsService
        .getTransportList(this.filterParamsFullList)
        .subscribe((res) => (this.transportOptions = res.transports));
      this.filteredListsService
        .getCompaniesList(this.filterParams)
        .subscribe(
          (res) => (this.customerCompanyOptions = res.customerCompanies)
        );
      this.usersService
        .getCompanyBranchList()
        .subscribe((res) => (this.companyBranches = res.branches));
    }
  }

  public submitOrder() {
    this.isLoading = true;
    markFormGroupTouched(this.orderImportForm);
    if (this.orderImportForm.invalid) {
      this.isLoading = false;
      return;
    }
    if (
      this.orderImportForm.get("status").value === this.orderStatuses.Closed
    ) {
      this.isLoading = false;
      return this.changeOrderStatus(this.orderStatuses.Closed);
    }
    let newOrder = JSON.parse(
      JSON.stringify(this.orderImportForm.getRawValue())
    );

    if (newOrder.suggestedDate) {
      newOrder.suggestedDate = moment(newOrder.suggestedDate).format(
        "YYYY-MM-DD"
      );
    }
    newOrder.transportId = newOrder.transport ? newOrder.transport.uuid : null;
    newOrder.customerCompanyId = newOrder.customerCompany
      ? newOrder.customerCompany.uuid
      : null;
    newOrder.bdo = newOrder.bdo ? newOrder.bdo.toString() : null;
    newOrder.bdoFree = Boolean(newOrder.bdoFree && !newOrder.bdo);
    newOrder.bdoPeopleCollection = Boolean(
      newOrder.bdoPeopleCollection && !newOrder.bdo
    );
    delete newOrder.transport;
    delete newOrder.customerCompany;

    newOrder.orderData = mapOrderDataApiValues(newOrder.orderData);
    newOrder.price = Number(String(newOrder.price).replace(",", ".")) || null;

    if (this.mode === "add" || this.mode === "clone") {
      this.ordersService.createOrder(newOrder).subscribe(
        () => {
          this.orderEditorDialogRef.close();
          this.orderEditorDialogRef.afterClosed().subscribe(() => {
            this.ordersService.onEditEvent.emit();
          });
          this.snackBar.open(
            this.translateService.instant("ORDERS.MESSAGES.CREATE_SUCCESS"),
            "",
            { duration: 5000 }
          );
        },
        () => {
          this.isLoading = false;
          this.snackBar.open(
            this.translateService.instant("ORDERS.MESSAGES.CREATE_ERROR"),
            "",
            { duration: 5000 }
          );
        }
      );
    } else if (this.mode === "view" && !this.canUserEdit) {
      this.ordersService
        .updateOrderDate(this.orderUuid, newOrder.suggestedDate)
        .subscribe(
          () => {
            this.orderEditorDialogRef.close();
            this.orderEditorDialogRef.afterClosed().subscribe(() => {
              this.ordersService.onEditEvent.emit();
            });
            this.snackBar.open(
              this.translateService.instant("ORDERS.MESSAGES.UPDATE_SUCCESS"),
              "",
              { duration: 5000 }
            );
          },
          () => {
            this.isLoading = false;
            this.snackBar.open(
              this.translateService.instant("ORDERS.MESSAGES.UPDATE_ERROR"),
              "",
              { duration: 5000 }
            );
          }
        );
    } else {
      this.ordersService.updateOrder(this.orderUuid, newOrder).subscribe(
        () => {
          this.orderEditorDialogRef.close();
          this.orderEditorDialogRef.afterClosed().subscribe(() => {
            this.ordersService.onEditEvent.emit();
          });
          this.snackBar.open(
            this.translateService.instant("ORDERS.MESSAGES.UPDATE_SUCCESS"),
            "",
            { duration: 5000 }
          );
        },
        () => {
          this.isLoading = false;
          this.snackBar.open(
            this.translateService.instant("ORDERS.MESSAGES.UPDATE_ERROR"),
            "",
            { duration: 5000 }
          );
        }
      );
    }
  }

  public deleteOrder() {
    let deleteDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false,
    });

    deleteDialogRef.componentInstance.confirmMessage =
      this.translateService.instant("ORDERS.MESSAGES.DELETE_QUESTION");
    deleteDialogRef.componentInstance.titleMessage =
      this.translateService.instant("ORDERS.MESSAGES.DELETE_ORDER_TITLE");
    deleteDialogRef.componentInstance.confirmButton =
      this.translateService.instant("ORDERS.DELETE");

    deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.ordersService.deleteOrder(this.orderUuid).subscribe(
          () => {
            this.orderEditorDialogRef.close();
            this.orderEditorDialogRef.afterClosed().subscribe(() => {
              this.ordersService.onEditEvent.emit();
            });
            this.snackBar.open(
              this.translateService.instant("ORDERS.MESSAGES.DELETE_SUCCESS"),
              "",
              { duration: 5000 }
            );
          },
          () => {
            this.snackBar.open(
              this.translateService.instant("ORDERS.MESSAGES.DELETE_ERROR"),
              "",
              { duration: 5000 }
            );
          }
        );
      }
    });
  }

  public generateTask() {
    if (this.order.salesAccepted) {
      this.orderEditorDialogRef.close();
      this.matDialog.open(TaskImportFormComponent, {
        panelClass: "task-editor-dialog",
        autoFocus: false,
        disableClose: true,
        data: {
          mode: "add",
          orders: [this.order],
        },
      });
    } else {
      this.snackBar.open(
        this.translateService.instant("ORDERS.MESSAGES.GENERATE_ERROR"),
        "",
        { duration: 5000 }
      );
    }
  }

  public viewTask() {
    this.orderEditorDialogRef.close();
    this.matDialog.open(TaskImportFormComponent, {
      panelClass: "task-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "view",
        taskNumber: this.order.taskNumber,
      },
    });
  }

  public changeOrderStatus(status: string) {
    let changeStatusDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false,
    });

    if (status === this.orderStatuses.Planned) {
      changeStatusDialogRef.componentInstance.confirmMessage =
        this.translateService.instant("ORDERS.MESSAGES.PLAN_QUESTION");
      changeStatusDialogRef.componentInstance.titleMessage =
        this.translateService.instant("ORDERS.MESSAGES.PLAN_ORDER_TITLE");
      changeStatusDialogRef.componentInstance.confirmButton =
        this.translateService.instant("ORDERS.PLAN_ORDER");

      changeStatusDialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.ordersService
            .changeOrderStatus(this.orderUuid, this.orderStatuses.Planned)
            .subscribe(
              () => {
                this.snackBar.open(
                  this.translateService.instant("ORDERS.MESSAGES.PLAN_SUCCESS"),
                  "",
                  { duration: 5000 }
                );
                this.order.status = this.orderStatuses.Planned;
                this.ordersService.onEditEvent.emit();
              },
              () => {
                this.snackBar.open(
                  this.translateService.instant("ORDERS.MESSAGES.PLAN_ERROR"),
                  "",
                  { duration: 5000 }
                );
              }
            );
        }
      });
    } else if (status === this.orderStatuses.Closed) {
      changeStatusDialogRef.componentInstance.confirmMessage =
        this.translateService.instant("ORDERS.MESSAGES.CLOSE_QUESTION");
      changeStatusDialogRef.componentInstance.titleMessage =
        this.translateService.instant("ORDERS.MESSAGES.CLOSE_ORDER_TITLE");
      changeStatusDialogRef.componentInstance.confirmButton =
        this.translateService.instant("ORDERS.MESSAGES.CLOSE_ORDER");
      changeStatusDialogRef.componentInstance.inputLabel =
        this.translateService.instant("ORDERS.MESSAGES.CLOSE_ORDER_LABEL");

      changeStatusDialogRef.afterClosed().subscribe((result) => {
        if (result && result.input) {
          this.ordersService
            .changeOrderStatus(this.orderUuid, status, {
              closedComment: result.input,
            })
            .subscribe(
              () => {
                this.snackBar.open(
                  this.translateService.instant(
                    "ORDERS.MESSAGES.CLOSE_SUCCESS"
                  ),
                  "",
                  { duration: 5000 }
                );
                this.order.status = this.orderStatuses.Closed;
                this.canCancelOrder = false;
                this.ordersService.onEditEvent.emit();
                this.orderEditorDialogRef.close();
              },
              () => {
                this.snackBar.open(
                  this.translateService.instant("ORDERS.MESSAGES.CLOSE_ERROR"),
                  "",
                  { duration: 5000 }
                );
              }
            );
        }
      });
    } else {
      changeStatusDialogRef.componentInstance.confirmMessage =
        this.translateService.instant("ORDERS.MESSAGES.CANCEL_QUESTION");
      changeStatusDialogRef.componentInstance.titleMessage =
        this.translateService.instant("ORDERS.MESSAGES.CANCEL_ORDER_TITLE");
      changeStatusDialogRef.componentInstance.confirmButton =
        this.translateService.instant("ORDERS.CANCEL_ORDER");

      changeStatusDialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.ordersService
            .changeOrderStatus(this.orderUuid, this.orderStatuses.Cancelled)
            .subscribe(
              () => {
                this.snackBar.open(
                  this.translateService.instant(
                    "ORDERS.MESSAGES.CANCEL_SUCCESS"
                  ),
                  "",
                  { duration: 5000 }
                );
                this.order.status = this.orderStatuses.Cancelled;
                this.canCancelOrder = false;
                this.ordersService.onEditEvent.emit();
                this.orderEditorDialogRef.close();
              },
              () => {
                this.snackBar.open(
                  this.translateService.instant("ORDERS.MESSAGES.CANCEL_ERROR"),
                  "",
                  { duration: 5000 }
                );
              }
            );
        }
      });
    }
  }

  public setCustomerCompany(customerCompany: Company) {
    if (customerCompany && customerCompany.trusted) {
      this.orderImportForm.get("salesAccepted").patchValue(true);
    } else {
      this.orderImportForm.get("salesAccepted").patchValue(false);
    }
  }

  public compareObjectsByUuid(val1, val2): boolean {
    return val1 && val2 && val1.uuid && val2.uuid && val1.uuid === val2.uuid;
  }

  public getTitle(): string {
    switch (this.mode) {
      case "add":
      case "clone":
        return this.translateService.instant("ORDERS.TITLE_ADD");
      case "edit":
        return this.translateService.instant("ORDERS.TITLE_EDIT");
      case "view":
        return this.translateService.instant("ORDERS.TITLE_VIEW");
      default:
        return;
    }
  }

  public showHistory() {
    this.matDialog.open(HistoryDialogComponent, {
      panelClass: "history-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        item: this.order,
        itemOrigin: ItemsOrigins.Order,
        itemId: this.orderUuid,
        itemNumber: this.order.orderNumber,
      },
    });
  }

  public enableEditMode() {
    this.orderEditorDialogRef.close();
    this.matDialog.open(OrderImportFormComponent, {
      panelClass: "order-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "edit",
        orderUuid: this.orderUuid,
      },
    });
  }

  public enableCloneMode() {
    this.orderEditorDialogRef.close();
    this.matDialog.open(OrderImportFormComponent, {
      panelClass: "order-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "clone",
        orderUuid: this.orderUuid,
      },
    });
  }

  public addCustomerCompany() {
    let editorDialogRef = this.matDialog.open(CompanyEditorComponent, {
      panelClass: "company-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "add",
      },
    });

    editorDialogRef.componentInstance.onAddCompany.subscribe((res) => {
      this.customerCompanyOptions.push(res);
      this.orderImportForm.patchValue({ customerCompany: res });
      this.companySelect.close();
    });
  }

  private createForm(inputData: OrderImport = new OrderImport()) {
    inputData.orderData = mapOrderDataToUuid(inputData.orderData);
    this.orderImportForm = this.formBuilder.group({
      customerCompany: new FormControl(
        inputData.customerCompany || null,
        Validators.required
      ),
      salesAccepted: new FormControl(
        inputData.salesAccepted || false,
        Validators.required
      ),
      companyBranchId: new FormControl(
        inputData.companyBranch ? inputData.companyBranch.uuid : null,
        Validators.required
      ),
      transport: new FormControl(
        inputData.transport || null,
        Validators.required
      ),
      registrationNumFront: new FormControl(
        inputData.registrationNumFront || null
      ),
      registrationNumBack: new FormControl(
        inputData.registrationNumBack || null
      ),
      orderData: new FormControl(inputData.orderData || [], [
        Validators.required,
        ValidateItemsList,
      ]),
      suggestedDate: new FormControl(
        this.mode === "clone"
          ? null
          : inputData.suggestedDate
          ? new Date(inputData.suggestedDate)
          : null
      ),
      tags: new FormControl(inputData.tags || []),
      kpoEmails: new FormControl(inputData.kpoEmails || null),
      comment: new FormControl(inputData.comment || null),
      bdo: new FormControl(inputData.bdo || null, Validators.pattern("[0-9]+")),
      bdoFree: new FormControl(
        inputData.bdoFree && !inputData.bdo && this.mode !== "add"
      ),
      bdoPeopleCollection: new FormControl(
        inputData.bdoPeopleCollection && !inputData.bdo && this.mode !== "add"
      ),
      reinvoice: new FormControl(inputData.reinvoice || false),
      price: new FormControl(
        inputData.price ? String(inputData.price).replace(".", ",") : ""
      ),
      reinvoiceNumber: new FormControl(inputData.reinvoiceNumber || ""),
      invoiceComment: new FormControl(inputData.invoiceComment || ""),
      referenceNumber: new FormControl(
        this.mode === "clone" ? null : inputData.referenceNumber || null
      ),
      orderType: new FormControl(inputData.orderType || OrderTypes.Import),
      status: new FormControl(
        this.mode === "clone" ? "new" : inputData.status || "new"
      ),
    });
    if (this.mode === "view") {
      this.orderImportForm.disable();
    }

    if (this.mode === "view" && !this.canUserEdit) {
      this.orderImportForm.disable();
      this.orderImportForm.get("suggestedDate").enable();
    }

    if (
      this.mode === "add" ||
      this.mode === "clone" ||
      this.user.userType !== this.userTypes.Logistician
    ) {
      this.orderImportForm.get("status").disable();
    }

    if (
      (this.mode === "clone" && this.orderImportForm.get("bdoFree").value) ||
      this.orderImportForm.get("bdo").value
    ) {
      this.orderImportForm.get("bdo").disable();
    }

    if (this.mode === "add" || this.mode === "clone" || this.mode === "edit") {
      this.orderImportForm.get("bdoFree").valueChanges.subscribe((bdoFree) => {
        const pickedCustomerCompany =
          this.orderImportForm.get("customerCompany").value;
        const bdoPeopleCollection = this.orderImportForm.get(
          "bdoPeopleCollection"
        ).value;
        if (bdoFree) {
          this.orderImportForm.get("bdoPeopleCollection").setValue(false);
          this.orderImportForm.get("bdo").clearValidators();
          this.orderImportForm.get("bdo").setValue("");
        } else if (
          !bdoFree &&
          !bdoPeopleCollection &&
          ((pickedCustomerCompany && pickedCustomerCompany.bdo) ||
            (this.order && this.order.bdo))
        ) {
          this.orderImportForm.get("bdo").setValidators(Validators.required);
          this.orderImportForm
            .get("bdo")
            .setValue(pickedCustomerCompany.bdo || this.order.bdo);
        } else if (
          !bdoFree &&
          !bdoPeopleCollection &&
          (!pickedCustomerCompany ||
            (!pickedCustomerCompany.bdo && this.mode !== "clone"))
        ) {
          this.orderImportForm.get("bdo").setValidators(Validators.required);
          this.orderImportForm.get("bdo").enable();
        } else {
          this.orderImportForm.get("bdo").setValue(this.bdo);
        }
      });
      this.orderImportForm
        .get("bdoPeopleCollection")
        .valueChanges.subscribe((bdoPeopleCollection) => {
          const bdoFree = this.orderImportForm.get("bdoFree").value;
          if (bdoPeopleCollection) {
            this.orderImportForm.get("bdoFree").setValue(false);
            this.orderImportForm.get("bdo").clearValidators();
            this.orderImportForm.get("bdo").setValue("");
          } else if (!bdoPeopleCollection && !bdoFree) {
            this.orderImportForm.get("bdo").setValidators(Validators.required);
            this.orderImportForm.get("bdo").enable();
          }
        });
    }

    if (this.mode === "add" || this.mode === "clone" || this.mode === "edit") {
      this.orderImportForm
        .get("customerCompany")
        .valueChanges.subscribe((pickedCustomerCompany) => {
          const bdoFree = this.orderImportForm.get("bdoFree").value;
          const bdoPeopleCollection = this.orderImportForm.get(
            "bdoPeopleCollection"
          ).value;
          if (
            pickedCustomerCompany &&
            pickedCustomerCompany.bdo &&
            !bdoFree &&
            !bdoPeopleCollection
          ) {
            this.orderImportForm.get("bdo").setValue(pickedCustomerCompany.bdo);
            this.orderImportForm.get("bdo").disable();
          } else if (
            pickedCustomerCompany &&
            !pickedCustomerCompany.bdo &&
            !bdoFree &&
            !bdoPeopleCollection
          ) {
            this.orderImportForm.get("bdo").setValue("");
            this.orderImportForm.get("bdo").enable();
          } else if (
            pickedCustomerCompany &&
            !pickedCustomerCompany.bdo &&
            (bdoFree || bdoPeopleCollection)
          ) {
            this.orderImportForm.get("bdo").setValue("");
            this.orderImportForm.get("bdo").disable();
          }
          this.orderImportForm
            .get("kpoEmails")
            .setValue(
              pickedCustomerCompany.kpo.map((kpo) => kpo.email).join(",")
            );
        });
    }
    if (this.orderImportForm.get("reinvoice").value === false) {
      this.orderImportForm.get("price").disable();
      this.orderImportForm.get("reinvoiceNumber").disable();
    }
  }

  private checkCustomerCompaniesMissing() {
    const chosenCustomerCompany =
      this.orderImportForm.get("customerCompany").value;
    if (
      chosenCustomerCompany &&
      !this.customerCompanyOptions.find(
        (item) => item.uuid === chosenCustomerCompany.uuid
      )
    ) {
      this.customerCompanyOptions.push(chosenCustomerCompany);
    }
  }

  private checkTransportMissing() {
    const chosenTransport = this.orderImportForm.get("transport").value;
    if (
      chosenTransport &&
      !this.transportOptions.find((item) => item.uuid === chosenTransport.uuid)
    ) {
      this.transportOptions.push(chosenTransport);
    }
  }

  private createSearchFilters() {
    this.searchCustomerCompany = new FormControl("");
    this.searchTransport = new FormControl("");

    this.searchCustomerCompany.valueChanges
      .debounceTime(300)
      .subscribe((value) => {
        this.filteredListsService
          .getCompaniesList({ ...this.filterParams, ...{ search: value } })
          .subscribe((response) => {
            this.customerCompanyOptions = response.customerCompanies;
            !value && this.checkCustomerCompaniesMissing();
          });
      });
    this.searchTransport.valueChanges.debounceTime(300).subscribe((value) => {
      this.filteredListsService
        .getTransportList({ ...this.filterParamsFullList, ...{ name: value } })
        .subscribe((response) => {
          this.transportOptions = response.transports;
          !value && this.checkTransportMissing();
        });
    });
  }

  public getTooltipCompany(company: any) {
    if (company) {
      return `${company.name} 
      ${company.address.street}
      ${company.address.postalCode} ${company.address.city}`;
    }
  }

  public reinvoiceChange(reinvoiceChecked: boolean) {
    if (reinvoiceChecked) {
      this.orderImportForm.get("price").enable();
      this.orderImportForm.get("reinvoiceNumber").enable();
    } else {
      this.orderImportForm.get("price").setValue(null);
      this.orderImportForm.get("price").disable();
      this.orderImportForm.get("reinvoiceNumber").setValue("");
      this.orderImportForm.get("reinvoiceNumber").disable();
    }
  }

  public clearStartDate(e) {
    e.stopPropagation();
    this.orderImportForm.get("suggestedDate").reset();
  }

  private isCloseDialogOpen: boolean = false; // without this, you can open the dialog multiple times
  @HostListener("window:keyup.esc")
  closeDialog() {
    if (!this.orderImportForm.dirty) {
      this.orderEditorDialogRef.close();
      return;
    }

    if (this.isCloseDialogOpen === false) {
      const saveDialogRef = this.matDialog.open(ConfirmModalComponent, {
        disableClose: true,
        autoFocus: false,
      });
      saveDialogRef.componentInstance.confirmMessage =
        this.translateService.instant(
          "SHARED.MESSAGES.CLOSE_DIALOG_WITHOUT_SAVE"
        );
      saveDialogRef.componentInstance.titleMessage =
        this.translateService.instant("SHARED.MESSAGES.USAVED_DATA");
      saveDialogRef.componentInstance.confirmButton =
        this.translateService.instant("SHARED.NO");
      saveDialogRef.componentInstance.skipButton =
        this.translateService.instant("SHARED.YES");

      this.isCloseDialogOpen = true;
      saveDialogRef.afterClosed().subscribe((result) => {
        this.isCloseDialogOpen = false;
        if (!result) {
          this.orderEditorDialogRef.close();
        }
      });
    }
  }
}
