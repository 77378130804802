<div class="calendar">
    <div class="header">
        <div class="logo">
            <mat-icon class="logo-icon">
                today
            </mat-icon>
            <span class="logo-text">{{'CALENDAR.TITLE' | translate}}</span>
        </div>
        <div class="search-wrapper-calendar">
            <div class="search">
                <label for="search" class="mr-8">
                    <mat-icon (click)="clearSearch()">{{searchTask.value ? 'clear' : 'search'}}</mat-icon>
                </label>
                <input id="search" [formControl]="searchTask"
                       [placeholder]="'CALENDAR.SEARCH_PLACEHOLDER' | translate">
            </div>
        </div>

        <div class="filters-wrapper">
            <div class="select-branch" *ngIf="selectedCompanyBranchId && companyBranches.length > 0">
                <mat-form-field>
                    <mat-select [(ngModel)]="selectedCompanyBranchId"
                                (selectionChange)="changeCompanyBranch($event.value)">
                        <mat-option *ngFor="let branch of companyBranches" [value]="branch.uuid">
                            {{branch.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="toolbar" fxLayout="row" fxLayoutAlign="start center">
                <button mat-icon-button
                        mwlCalendarToday
                        [(viewDate)]="viewDate"
                        (viewDateChange)="changeDate($event)"
                        matTooltip="{{'SHARED.TODAY' | translate}}">
                    <mat-icon>today</mat-icon>
                </button>
                <button mat-icon-button (click)="changeCalendarView('day', 'day')"
                        matTooltip="{{'SHARED.DAY' | translate}}">
                    <mat-icon>view_day</mat-icon>
                </button>

                <button mat-icon-button (click)="changeCalendarView('week', 'workweek')"
                        matTooltip="{{'SHARED.WORKWEEK' | translate}}">
                    <mat-icon>chrome_reader_mode</mat-icon>
                </button>

                <button mat-icon-button (click)="changeCalendarView('week', 'week')"
                        matTooltip="{{'SHARED.WEEK' | translate}}">
                    <mat-icon>view_week</mat-icon>
                </button>

                <button mat-icon-button (click)="changeCalendarView('month', 'month')"
                        matTooltip="{{'SHARED.MONTH' | translate}}">
                    <mat-icon>view_module</mat-icon>
                </button>
            </div>
        </div>

        <button *ngIf="user.userType === userTypes.Logistician" mat-fab class="add-task-button"
                [matTooltip]="'TASKS.TITLE_ADD' | translate"
                matTooltipPosition="above"
                [disabled]="!selectedCompanyBranchId"
                (click)="addTask()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <div *ngIf="!selectedCompanyBranchId" class="select-branch warning-text">
        {{'CALENDAR.CHOOSE_BRANCH' | translate}}
    </div>

    <div fxLayout="row" class="dates-picker-row" fxLayoutAlign="space-around center">
        <div class="sales-filter">
            <mat-form-field appearance="outline" style="width: 170px;">
                <mat-label>{{'USERS.TYPES.SALES' | translate}}</mat-label>
                <mat-select (selectionChange)="salesFilterChanged($event)">
                    <mat-option *ngFor="let usr of salesList" [value]="usr.uuid">
                        {{usr.surname}} {{usr.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="sales-filter">
            <mat-form-field appearance="outline" style="width: 170px;">
                <mat-label>{{'TASKS.STATUS' | translate}}</mat-label>
                <mat-select multiple (selectionChange)="statusFilterChanged($event)">
                    <mat-option *ngFor="let status of taskStatusesList" [value]="status.value">
                        {{'TASKS.FILTERS.STATUSES.' + status.display | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="dates-picker"
             [ngClass]="{'dates-picker-offset': selectedCompanyBranchId && selectedCompanyBranchName}">
            <button mat-icon-button
                    class="arrow left"
                    mwlCalendarPreviousView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="changeDate($event)">
                <mat-icon>chevron_left</mat-icon>
            </button>
            <div class="title">
                {{ viewDate | calendarDate:(view + 'ViewTitle'):'pl':1:excludeDays() }}
            </div>
            <button mat-icon-button
                    class="arrow right"
                    mwlCalendarNextView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="changeDate($event)">
                <mat-icon>chevron_right</mat-icon>
            </button>
        </div>
        <app-pdf-downloader *ngIf="selectedCompanyBranchId && selectedCompanyBranchName"
                            class="pdf-downloader"
                            [companyBranchName]="selectedCompanyBranchName"
                            [companyBranchId]="selectedCompanyBranchId"
        >
        </app-pdf-downloader>
    </div>
    <ng-template #monthCellTemplate let-day="day" let-locale="locale">
        <div>
            <div class="month-day-cell">
        <span class="cal-day-badge"
              [ngClass]="{'no-events': day.events.length === 0}"
              [matTooltip]="getDayTooltipMessage(day.events)"
              matTooltipPosition="above"
              matTooltipClass="multiline-tooltip"
        >
          {{'CALENDAR.TASKS' | translate}}: {{ getDayTasksCount(day.events) }}
        </span>
                <span class="cal-day-number">
            {{ day.date | calendarDate:'monthViewDayNumber':locale }}
        </span>
            </div>
            <div class="events-summary">
                <app-day-events-summary
                        [events]="day.events"
                        [selectedDate]="day.date"
                        [dailySummary]="getSummaryObject(true)"
                        [cumulativelySummary]="getSummaryObject()"
                        [totalSummary]="totalResponse"
                >
                </app-day-events-summary>
            </div>
            <button *ngIf="(user.userType === userTypes.Logistician && user.roleType === roleTypes.Admin) || user.userType === userTypes.Manager"
                    matTooltip="{{'CALENDAR.TOGGLE_BLOCK_DAY_TOOLTIP' | translate}}" mat-icon-button
                    class="block-day-button"
                    (click)="toggleDayBlocked(day.date)">
                <mat-icon>warning</mat-icon>
            </button>
        </div>
    </ng-template>

    <div fusePerfectScrollbar>
        <div class="content" [ngClass]="{'all-week-view': viewName === 'week'}">
            <div class="main-calendar-wrapper"
                 [ngClass]="{'blocked-edition': user.userType !== userTypes.Logistician }">
                <div [ngClass]="{'additional-field': !folded && !calendarSplitView  , 'additional-field-close-menu':folded && !calendarSplitView}"
                     *ngIf="viewName !== 'month'">
                    <div class="empty-cell"></div>

                    <!--Selected day-->
                    <app-additional-field
                            *ngIf="viewName === 'day'"
                            class="day-field-cell"
                            [diffBetweenStartOfWeek]="0"
                            [selectedDate]="selectedDay.date"
                            [companyBranchId]="selectedCompanyBranchId"
                            [description]="getDescriptionForAdditionalFieldInGivenOneDay()"
                    >
                    </app-additional-field>

                    <ng-container *ngIf="noteList && !calendarSplitView">
                        <ng-container
                                *ngFor="let i of this.viewName === 'week' ? [0, 1, 2, 3, 4, 5, 6] : this.viewName === 'workweek' ? [0, 1, 2, 3, 4] : []">
                            <app-additional-field
                                    *ngIf="viewName !== 'day'"
                                    class="day-field-cell"
                                    [diffBetweenStartOfWeek]="i"
                                    [startDate]="lastRequestParams.dateFrom"
                                    [companyBranchId]="selectedCompanyBranchId"
                                    [calendarView]="'week'"
                                    [attr.data-cy]="'editNote'+i"
                                    [description]="getDescriptionForAdditionalFieldInGivenDayOfWeek(i)"
                            >
                            </app-additional-field>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="tabs-group" *ngIf="viewName !== 'month'">
                    <div *ngIf="mainCalendarView.export"
                         (click)="toggleActiveTaskTab('export')"
                         class="calendar-tab export"
                         [ngClass]="{'inactive-tab': !tasksTabsActive.export || !selectedCompanyBranchId}"
                    >
                        <p>{{'CALENDAR.TASK_TYPES.EXPORT' | translate}}</p>
                        <mat-icon (click)="splitCalendarView('export')">arrow_forward</mat-icon>
                    </div>
                    <div *ngIf="mainCalendarView.import"
                         (click)="toggleActiveTaskTab('import')"
                         class="calendar-tab import"
                         [ngClass]="{'inactive-tab': !tasksTabsActive.import || !selectedCompanyBranchId}"
                    >
                        <p> {{'CALENDAR.TASK_TYPES.IMPORT' | translate}}</p>
                        <mat-icon (click)="splitCalendarView('import')">arrow_forward</mat-icon>
                    </div>
                    <div *ngIf="mainCalendarView.receiving"
                         (click)="toggleActiveTaskTab('receiving')"
                         class="calendar-tab receiving"
                         [ngClass]="{'inactive-tab': !tasksTabsActive.receiving || !selectedCompanyBranchId}"
                    >
                        <p>{{'CALENDAR.TASK_TYPES.RECEIVING' | translate}}</p>
                        <mat-icon (click)="splitCalendarView('receiving')">arrow_forward</mat-icon>
                    </div>
                    <div *ngIf="mainCalendarView.cancelled"
                         (click)="toggleActiveTaskTab('cancelled')"
                         class="calendar-tab cancelled"
                         [ngClass]="{'inactive-tab': !tasksTabsActive.cancelled || !selectedCompanyBranchId}"
                    >
                        <p class="cancelled-tab-title">{{'CALENDAR.TASK_TYPES.CANCELLED' | translate}}</p>
                        <mat-icon (click)="splitCalendarView('cancelled')">arrow_forward</mat-icon>
                    </div>
                </div>
                <div [ngSwitch]="viewName">
                    <mwl-calendar-month-view
                            *ngSwitchCase="'month'"
                            [locale]="locale"
                            [weekStartsOn]="1"
                            [viewDate]="viewDate"
                            [events]="mainCalendarTasks"
                            [refresh]="refresh"
                            [activeDayIsOpen]="activeDayIsOpen"
                            (beforeViewRender)="beforeMonthViewRender($event)"
                            (dayClicked)="dayClicked($event.day)"
                            (eventClicked)="editTask($event.event)"
                            [cellTemplate]="monthCellTemplate"
                            (eventTimesChanged)="updateOnDragOrResize($event)">
                    </mwl-calendar-month-view>
                    <mwl-calendar-week-view
                      #calendarComponent
                      *ngSwitchCase="'week'"
                      [ngClass]="{'editable-task': user.userType === userTypes.Logistician}"
                      [locale]="locale"
                      [dayStartHour]="6"
                      [dayEndHour]="17"
                      [dayEndMinute]="30"
                      [weekStartsOn]="1"
                      [viewDate]="viewDate"
                      (viewDateChange)="selectedDay = {date:$event}"
                      [events]="mainCalendarTasks"
                      [refresh]="refresh"
                      (beforeViewRender)="beforeWeekViewRender($event)"
                      [tooltipTemplate]="eventTooltipTemplate"
                      (hourSegmentClicked)="addTask($event.date)"
                      (dayHeaderClicked)="toggleDayBlocked($event.day.date)"
                      (eventClicked)="editTask($event.event)"
                      (eventTimesChanged)="updateOnDragOrResize($event)">
                    </mwl-calendar-week-view>
                    <mwl-calendar-week-view
                      #calendarComponent
                      *ngSwitchCase="'workweek'"
                      [ngClass]="{'editable-task': user.userType === userTypes.Logistician}"
                      [locale]="locale"
                      [dayStartHour]="6"
                      [dayEndHour]="17"
                      [dayEndMinute]="30"
                      [weekStartsOn]="1"
                      [excludeDays]="[6, 0]"
                      [viewDate]="viewDate"
                      (viewDateChange)="selectedDay = {date:$event}"
                      [events]="mainCalendarTasks"
                      [refresh]="refresh"
                      (beforeViewRender)="beforeWeekViewRender($event)"
                      [tooltipTemplate]="eventTooltipTemplate"
                      (hourSegmentClicked)="addTask($event.date)"
                      (dayHeaderClicked)="toggleDayBlocked($event.day.date)"
                      (eventClicked)="editTask($event.event)"
                      (eventTimesChanged)="updateOnDragOrResize($event)">
                    </mwl-calendar-week-view>
                    <mwl-calendar-day-view
                            *ngSwitchCase="'day'"
                            [ngClass]="{'editable-task': user.userType === userTypes.Logistician}"
                            [locale]="locale"
                            [dayStartHour]="6"
                            [dayEndHour]="17"
                            [dayEndMinute]="30"
                            [viewDate]="viewDate"
                            (viewDateChange)="selectedDay = {date:$event}"
                            [events]="mainCalendarTasks"
                            [refresh]="refresh"
                            (beforeViewRender)="beforeDayViewRender($event)"
                            [tooltipTemplate]="eventTooltipTemplate"
                            (hourSegmentClicked)="addTask($event.date)"
                            (eventClicked)="editTask($event.event)"
                            (eventTimesChanged)="updateOnDragOrResize($event)">
                    </mwl-calendar-day-view>
                </div>
                <div [ngClass]="{'additional-summary': !folded && !calendarSplitView  , 'additional-summary-close-menu':folded && !calendarSplitView,
        'additional-summary-split': calendarSplitView && !folded, 'additional-summary-split-close-menu':calendarSplitView && folded}"
                     *ngIf="viewName !== 'month'">
                    <div class="empty-cell"></div>

                    <!--Selected day-->
                    <app-day-events-summary
                            *ngIf="viewName === 'day'"
                            [events]="mainCalendarTasks"
                            class="day-summary-cell"
                            [selectedDate]="selectedDay.date"
                            [dailySummary]="getSummaryObject(true)"
                            [cumulativelySummary]="getSummaryObject()"
                            [totalSummary]="totalResponse"
                    >
                    </app-day-events-summary>

                    <!--Monday-->
                    <app-day-events-summary
                            *ngIf="viewName !== 'day'"
                            class="day-summary-cell"
                            [events]="mainCalendarTasks"
                            [diffBetweenStartOfWeek]="1"
                            [selectedDate]="selectedDay.date"
                            [calendarView]="'week'"
                            [dailySummary]="getSummaryObject(1, true)"
                            [cumulativelySummary]="getSummaryObject(1)"
                            [totalSummary]="totalResponse"
                    >
                    </app-day-events-summary>

                    <!--Tuesday-->
                    <app-day-events-summary
                            *ngIf="viewName !== 'day'"
                            class="day-summary-cell"
                            [events]="mainCalendarTasks"
                            [diffBetweenStartOfWeek]="2"
                            [selectedDate]="selectedDay.date"
                            [calendarView]="'week'"
                            [dailySummary]="getSummaryObject(2,true)"
                            [cumulativelySummary]="getSummaryObject(2)"
                            [totalSummary]="totalResponse"
                    >
                    </app-day-events-summary>

                    <!--Wednesday-->
                    <app-day-events-summary
                            *ngIf="viewName !== 'day'"
                            class="day-summary-cell"
                            [events]="mainCalendarTasks"
                            [diffBetweenStartOfWeek]="3"
                            [selectedDate]="selectedDay.date"
                            [calendarView]="'week'"
                            [dailySummary]="getSummaryObject(3, true)"
                            [cumulativelySummary]="getSummaryObject(3)"
                            [totalSummary]="totalResponse"
                    >
                    </app-day-events-summary>

                    <!--Thursday-->
                    <app-day-events-summary
                            *ngIf="viewName !== 'day'"
                            class="day-summary-cell"
                            [events]="mainCalendarTasks"
                            [diffBetweenStartOfWeek]="4"
                            [selectedDate]="selectedDay.date"
                            [calendarView]="'week'"
                            [dailySummary]="getSummaryObject(4,true)"
                            [cumulativelySummary]="getSummaryObject(4)"
                            [totalSummary]="totalResponse"
                    >
                    </app-day-events-summary>

                    <!--Friday-->
                    <app-day-events-summary
                            *ngIf="viewName !== 'day'"
                            class="day-summary-cell"
                            [events]="mainCalendarTasks"
                            [diffBetweenStartOfWeek]="5"
                            [selectedDate]="selectedDay.date"
                            [calendarView]="'week'"
                            [dailySummary]="getSummaryObject(5,true)"
                            [cumulativelySummary]="getSummaryObject(5)"
                            [totalSummary]="totalResponse"
                    >
                    </app-day-events-summary>

                    <!--Saturday-->
                    <app-day-events-summary
                            *ngIf="viewName === 'week'"
                            class="day-summary-cell"
                            [events]="mainCalendarTasks"
                            [diffBetweenStartOfWeek]="6"
                            [selectedDate]="selectedDay.date"
                            [calendarView]="'week'"
                            [dailySummary]="getSummaryObject(6,true)"
                            [cumulativelySummary]="getSummaryObject(6)"
                            [totalSummary]="totalResponse"
                    >
                    </app-day-events-summary>

                    <!--Sunday-->
                    <app-day-events-summary
                            *ngIf="viewName === 'week'"
                            class="day-summary-cell"
                            [events]="mainCalendarTasks"
                            [diffBetweenStartOfWeek]="7"
                            [selectedDate]="selectedDay.date"
                            [calendarView]="'week'"
                            [dailySummary]="getSummaryObject(7,true)"
                            [cumulativelySummary]="getSummaryObject(7)"
                            [totalSummary]="totalResponse"
                    >
                    </app-day-events-summary>

                </div>
            </div>
            <div class="additional-calendar-wrapper" *ngIf="calendarSplitView">
                <div class="tabs-group">
                    <div *ngIf="additionalCalendarView.export"
                         (click)="toggleActiveTaskTab('export')"
                         class="calendar-tab export"
                         [ngClass]="{'inactive-tab': !tasksTabsActive.export}"
                    >
                        <p>{{'CALENDAR.TASK_TYPES.EXPORT' | translate}}</p>
                        <mat-icon (click)="mergeCalendarView('export')">arrow_back</mat-icon>
                    </div>
                    <div *ngIf="additionalCalendarView.import"
                         (click)="toggleActiveTaskTab('import')"
                         class="calendar-tab import"
                         [ngClass]="{'inactive-tab': !tasksTabsActive.import}"
                    >
                        <p> {{'CALENDAR.TASK_TYPES.IMPORT' | translate}}</p>
                        <mat-icon (click)="mergeCalendarView('import')">arrow_back</mat-icon>
                    </div>
                    <div *ngIf="additionalCalendarView.receiving"
                         (click)="toggleActiveTaskTab('receiving')"
                         class="calendar-tab receiving"
                         [ngClass]="{'inactive-tab': !tasksTabsActive.receiving}"
                    >
                        <p>{{'CALENDAR.TASK_TYPES.RECEIVING' | translate}}</p>
                        <mat-icon (click)="mergeCalendarView('receiving')">arrow_back</mat-icon>
                    </div>
                    <div *ngIf="additionalCalendarView.cancelled"
                         (click)="toggleActiveTaskTab('cancelled')"
                         class="calendar-tab cancelled"
                         [ngClass]="{'inactive-tab': !tasksTabsActive.cancelled}"
                    >
                        <p>{{'CALENDAR.TASK_TYPES.CANCELLED' | translate}}</p>
                        <mat-icon (click)="mergeCalendarView('cancelled')">arrow_back</mat-icon>
                    </div>
                </div>
                <div [ngSwitch]="viewName">
                    <mwl-calendar-week-view
                      #additionalCalendarComponent
                      *ngSwitchCase="'week'"
                      [ngClass]="{'editable-task': user.userType === userTypes.Logistician}"
                      [locale]="locale"
                      [dayStartHour]="6"
                      [dayEndHour]="14"
                      [weekStartsOn]="1"
                      [viewDate]="viewDate"
                      (viewDateChange)="selectedDay = {date:$event}"
                      [events]="additionalCalendarTasks"
                      [refresh]="refresh"
                      (beforeViewRender)="beforeWeekViewRender($event)"
                      [tooltipTemplate]="eventTooltipTemplate"
                      (hourSegmentClicked)="addTask($event.date)"
                      (dayHeaderClicked)="toggleDayBlocked($event.day.date)"
                      (eventClicked)="editTask($event.event)"
                      (eventTimesChanged)="updateOnDragOrResize($event)">
                    </mwl-calendar-week-view>
                    <mwl-calendar-week-view
                      #additionalCalendarComponent
                      *ngSwitchCase="'workweek'"
                      [ngClass]="{'editable-task': user.userType === userTypes.Logistician}"
                      [locale]="locale"
                      [dayStartHour]="6"
                      [dayEndHour]="14"
                      [weekStartsOn]="1"
                      [excludeDays]="[6, 0]"
                      [viewDate]="viewDate"
                      (viewDateChange)="selectedDay = {date:$event}"
                      [events]="additionalCalendarTasks"
                      [refresh]="refresh"
                      (beforeViewRender)="beforeWeekViewRender($event)"
                      [tooltipTemplate]="eventTooltipTemplate"
                      (hourSegmentClicked)="addTask($event.date)"
                      (dayHeaderClicked)="toggleDayBlocked($event.day.date)"
                      (eventClicked)="editTask($event.event)"
                      (eventTimesChanged)="updateOnDragOrResize($event)">
                    </mwl-calendar-week-view>
                    <mwl-calendar-day-view
                            *ngSwitchCase="'day'"
                            [ngClass]="{'editable-task': user.userType === userTypes.Logistician}"
                            [locale]="locale"
                            [dayStartHour]="6"
                            [dayEndHour]="14"
                            [viewDate]="viewDate"
                            (viewDateChange)="selectedDay = {date:$event}"
                            [events]="additionalCalendarTasks"
                            [refresh]="refresh"
                            (beforeViewRender)="beforeDayViewRender($event)"
                            [tooltipTemplate]="eventTooltipTemplate"
                            (hourSegmentClicked)="addTask($event.date)"
                            (dayClicked)="dayClicked($event.day)"
                            (eventClicked)="editTask($event.event)"
                            (eventTimesChanged)="updateOnDragOrResize($event)">
                    </mwl-calendar-day-view>
                </div>
                <div [ngClass]="{
        'additional-summary-split': calendarSplitView && !folded, 'additional-summary-split-close-menu':calendarSplitView && folded, 'summary-hidden':calendarSplitView}"
                     *ngIf="viewName !== 'month'">

                    <!--Selected day-->
                    <app-day-events-summary
                            *ngIf="viewName === 'day'"
                            [events]="additionalCalendarTasks"
                            class="day-summary-cell"
                            [selectedDate]="selectedDay.date"
                    >
                    </app-day-events-summary>

                    <!--Monday-->
                    <app-day-events-summary
                            *ngIf="viewName !== 'day'"
                            class="day-summary-cell"
                            [events]="additionalCalendarTasks"
                            [diffBetweenStartOfWeek]="1"
                            [selectedDate]="selectedDay.date"
                            [calendarView]="'week'"
                    >
                    </app-day-events-summary>

                    <!--Tuesday-->
                    <app-day-events-summary
                            *ngIf="viewName !== 'day'"
                            class="day-summary-cell"
                            [events]="additionalCalendarTasks"
                            [diffBetweenStartOfWeek]="2"
                            [selectedDate]="selectedDay.date"
                            [calendarView]="'week'"
                    >
                    </app-day-events-summary>

                    <!--Wednesday-->
                    <app-day-events-summary
                            *ngIf="viewName !== 'day'"
                            class="day-summary-cell"
                            [events]="additionalCalendarTasks"
                            [diffBetweenStartOfWeek]="3"
                            [selectedDate]="selectedDay.date"
                            [calendarView]="'week'"
                    >
                    </app-day-events-summary>

                    <!--Thursday-->
                    <app-day-events-summary
                            *ngIf="viewName !== 'day'"
                            class="day-summary-cell"
                            [events]="additionalCalendarTasks"
                            [diffBetweenStartOfWeek]="4"
                            [selectedDate]="selectedDay.date"
                            [calendarView]="'week'"
                    >
                    </app-day-events-summary>

                    <!--Friday-->
                    <app-day-events-summary
                            *ngIf="viewName !== 'day'"
                            class="day-summary-cell"
                            [events]="additionalCalendarTasks"
                            [diffBetweenStartOfWeek]="5"
                            [selectedDate]="selectedDay.date"
                            [calendarView]="'week'"
                    >
                    </app-day-events-summary>

                    <!--Saturday-->
                    <app-day-events-summary
                            *ngIf="viewName === 'week'"
                            class="day-summary-cell"
                            [events]="additionalCalendarTasks"
                            [diffBetweenStartOfWeek]="6"
                            [selectedDate]="selectedDay.date"
                            [calendarView]="'week'"
                    >
                    </app-day-events-summary>

                    <!--Sunday-->
                    <app-day-events-summary
                            *ngIf="viewName === 'week'"
                            class="day-summary-cell"
                            [events]="additionalCalendarTasks"
                            [diffBetweenStartOfWeek]="7"
                            [selectedDate]="selectedDay.date"
                            [calendarView]="'week'"
                    >
                    </app-day-events-summary>

                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #eventTooltipTemplate let-event="event">
    <div class="cal-tooltip">
        <div class="cal-tooltip-inner">
            <h4>{{event.tooltipTitle}}</h4>
            <mat-divider></mat-divider>
            <div class="tooltip-section section-horizontal">
                <div class="section-item">
                    <p>{{'CALENDAR.TRANSPORT' | translate}}</p>
                    <h5>{{event.transport.name}}</h5>
                </div>
                <div class="section-item">
                    <p class="task-number">{{event.taskNumber}}</p>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div *ngIf="event.type === 'export'" class="tooltip-section">
                <p class="section-heading">{{'CALENDAR.FRACTIONS' | translate}}</p>
                <h5 *ngFor="let fraction of event.fractions.name">
                    {{fraction}}
                </h5>
                <div class="tooltip-section section-horizontal">
                    <p>{{'CALENDAR.TOTAL_WEIGHT' | translate}}</p>
                    <h5>
                        <span>{{event.fractions.totalWeight}} </span>{{'CALENDAR.KG' | translate}}
                    </h5>
                </div>
            </div>
            <div *ngIf="event.type !== 'export'" class="tooltip-section">
                <p class="section-heading">{{'CALENDAR.RECYCLING_ITEMS' | translate}}</p>
                <ng-container *ngFor="let recyclingItem of event.recyclingItemsWeights; let i=index">
                    <h5 *ngIf="i < 8">
                        {{recyclingItem.name}}: <span>{{recyclingItem.value}} </span>kg
                    </h5>
                </ng-container>
                <h5 *ngIf="event.recyclingItemsWeights.length > 8">
                    ...
                </h5>
                <div class="tooltip-section section-horizontal">
                    <p>{{'CALENDAR.TOTAL_WEIGHT' | translate}}</p>
                    <h5>
                        <span>{{getOrderTotalWeight(event.recyclingItemsWeights)}} </span>{{'CALENDAR.KG' | translate}}
                    </h5>
                </div>
            </div>
        </div>
    </div>
</ng-template>