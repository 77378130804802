var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
export var OrderSatuses;
(function (OrderSatuses) {
    OrderSatuses["New"] = "new";
    OrderSatuses["Read"] = "read";
    OrderSatuses["Planned"] = "planned";
    OrderSatuses["Cancelled"] = "cancelled";
    OrderSatuses["Closed"] = "closed";
})(OrderSatuses || (OrderSatuses = {}));
var BasicOrder = /** @class */ (function () {
    function BasicOrder() {
    }
    return BasicOrder;
}());
export { BasicOrder };
var Order = /** @class */ (function (_super) {
    __extends(Order, _super);
    function Order() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Order;
}(BasicOrder));
export { Order };
var OrderExport = /** @class */ (function (_super) {
    __extends(OrderExport, _super);
    function OrderExport() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return OrderExport;
}(BasicOrder));
export { OrderExport };
var OrderImport = /** @class */ (function (_super) {
    __extends(OrderImport, _super);
    function OrderImport() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return OrderImport;
}(BasicOrder));
export { OrderImport };
var OrderReceiving = /** @class */ (function (_super) {
    __extends(OrderReceiving, _super);
    function OrderReceiving() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return OrderReceiving;
}(BasicOrder));
export { OrderReceiving };
