import { CustomerCompanyBranch } from "./company-branches.model";
import {
  FilteredCompany,
  FilteredFraction,
  FilteredOrderData,
  FilteredPackageType,
  FilteredRecyclingItem,
  FilteredTransport,
} from "./filtered-items.model";
import { Transport } from "./transport.model";

export enum OrderSatuses {
  New = "new",
  Read = "read",
  Planned = "planned",
  Cancelled = "cancelled",
  Closed = "closed",
}

export interface OrdersListRespone {
  totalCount: number;
  orders: Order[];
}

export interface NewOrder {
  customerCompanyId: string;
  salesAccepted: boolean;
  companyBranchId: string;
  orderType: string;
  transportId?: string;
  registrationNumFront?: string;
  registrationNumBack?: string;
  address?: {
    city: string;
    street: string;
    postalCode: string;
    country: string;
  };
  orderData?: FilteredOrderData[];
  suggestedDate?: string;
  comment?: string;
  fractionIds?: string[];
  value?: number;
  unit?: string;
}

export class BasicOrder {
  uuid: string;
  orderNumber: string;
  salesAcceptedDate: string;
  createdDate: string;
  updatedDate: string;
  suggestedDate: string;
  orderType: string;
  tags: string[];
  comment: string;
  referenceNumber: string;
  status: string;
  reinvoice?: boolean;
  price?: number;
  reinvoiceNumber?: string;
  invoiceComment?: string;
  kpoEmails?: string;
  companyBranch: {
    uuid: string;
    name: string;
  };
  createdBy: {
    uuid: string;
    name: string;
    surname: string;
  };
  task: {
    uuid: string;
    date: string;
  };
  taskId?: string;
  taskNumber?: string;
}

export type TOrder = OrderReceiving | OrderExport | OrderImport;

export class Order extends BasicOrder {
  customerCompany: {
    uuid: string;
    name: string;
  };
  salesAccepted?: string;
}

export class OrderExport extends BasicOrder {
  fractionsId: string;
  fractions: FilteredFraction[];
  customerCompanyId: string;
  customerCompany: FilteredCompany;
  customerCompanyBranch: CustomerCompanyBranch;
  companyBranch: {
    uuid: string;
    name: string;
  };
  transportId: string;
  transport: Transport;
  value: number;
  unit: string;
  isReady: boolean;
}

export class OrderImport extends BasicOrder {
  customerCompanyId: string;
  customerCompany: FilteredCompany;
  companyBranchId: string;
  transportId: string;
  transport: FilteredTransport;
  salesAccepted: boolean;
  orderData: FilteredOrderData[];
  recyclingItems: FilteredRecyclingItem[];
  packageTypes: FilteredPackageType[];
  registrationNumFront: string;
  registrationNumBack: string;
  bdo: string;
  bdoFree: boolean;
  bdoPeopleCollection: boolean;
}

export class OrderReceiving extends BasicOrder {
  customerCompanyId: string;
  customerCompany: FilteredCompany;
  customerCompanyBranch: CustomerCompanyBranch;
  companyBranchId: string;
  salesAccepted: boolean;
  orderData: FilteredOrderData[];
  availableHoursFrom: string;
  availableHoursTo: string;
  emailOrderReminder: boolean;
  bdo: string;
  bdoFree: boolean;
  bdoPeopleCollection: boolean;
  files: OrderFile[];
  totalWeight?: any;
}

export interface OrderFile {
  fileId: string;
  name: string;
  type?: string;
  url?: string;
}
