import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import { FuseTranslationLoaderService } from "../../../../../@fuse/services/translation-loader.service";
import { locale as english } from "../../../../translations/en";
import { locale as polish } from "../../../../translations/pl";

@Component({
  selector: "app-confirm-modal",
  templateUrl: "./confirm-modal.component.html",
  styleUrls: ["./confirm-modal.component.scss"],
})
export class ConfirmModalComponent implements OnInit {
  public confirmMessage: string;
  public titleMessage: string;
  public confirmButton: string;
  public skipButton?: string;
  public inputLabel?: string;
  public form: FormGroup;

  constructor(
    public matDialogRef: MatDialogRef<ConfirmModalComponent>,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _formBuilder: FormBuilder
  ) {
    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      input: new FormControl(
        null,
        this.inputLabel ? Validators.required : null
      ),
    });
  }
  onConfirm(): void {
    if (this.inputLabel) {
      if (this.form.valid) {
        this.matDialogRef.close({ input: this.form.value.input });
      }
    } else {
      this.matDialogRef.close(true);
    }
  }
}
