<h1 matDialogTitle>{{titleMessage}}</h1>
<div mat-dialog-content>{{confirmMessage}}</div>
<div *ngIf="inputLabel">
  <form [formGroup]="form">
    <div fxLayout="row" fxLayoutAlign="start start">
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>{{inputLabel | translate}}</mat-label>
        <input formControlName="input" matInput [attr.data-cy]="'input'"/>
      </mat-form-field>
    </div>
    </form>
</div>
<div mat-dialog-actions class="pt-24" fxLayoutAlign="end center">
  <button mat-button class="mr-16" (click)="matDialogRef.close(false)" [attr.data-cy]="'cancel'">
    {{skipButton ? skipButton : 'SHARED.CANCEL' | translate}}
  </button>
  <button mat-raised-button class="mat-accent" (click)="onConfirm()" [attr.data-cy]="'confirm'">
    {{confirmButton}}
  </button>
</div>
